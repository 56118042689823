import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import React, {FC, useState} from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import {Helmet} from "react-helmet";
import Label from "../../components/Label/Label";
import Input from "../../shared/Input/Input";
import Textarea from "../../shared/Textarea/Textarea";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import axios from "axios";
import CheckOutPagePageMain from "../CheckOutPage/CheckOutPage";

function pad(num: number) {
    let dd = num.toString();
    while (dd.length < 2) dd = "0" + num;
    return dd;
}

function convertformat(curr_dt: Date | null) {
    if (curr_dt === null) {
        return null;
    }
    return curr_dt.getFullYear() + "-" + pad(curr_dt.getMonth() + 1) + "-" + pad(curr_dt.getDate());
}

export interface ListingFlightsPageProps {
    className?: string;
    lang: boolean | true
}

export interface result {
    id: string;
    date: string;
    time: string;
}

export interface pagination {
    count: number;
    next: string | null;
    previous: string | null;
    results: result[];
}

const info = [
    {
        title: "🗺 ADDRESS",
        desc: "311 Abou Quer, AR Riyadah, Sidi Gaber, Alexandria Governorate.",
        arTitle: "🗺 العنوان",
        arDesc: "311 طريق أبو قير, سيدي جابر, الأسكندرية",
    },
    {
        title: "💌 EMAIL",
        desc: "info@alex-cure.com",
        arTitle: "💌 البريد الالكتروني",
        arDesc: "info@alex-cure.com",
    },
    {
        title: "☎ PHONE",
        desc: "+2 034274445",
        arTitle: "☎ الهاتف",
        arDesc: "034274445 2+ ",
    },
];

const ListingFlightsPage: FC<ListingFlightsPageProps> = ({
                                                             className = "",
                                                             lang
                                                         }) => {
    const [startDate, setStartDate] = useState<Date | null>(
        new Date()
    );
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    //
    const [timeSlots, setTimeSlots] = useState<pagination | null>(null);
    //
    const [hours, setHours] = useState("09");
    const [minutes, setMinutes] = useState("00");
    //
    const [checkOut, setCheckOut] = useState(false);
    //
    const [err, setErr] = useState("");
    //
    const onSearch = () => {
        let hour: string;
        if (["01", "02", "03"].includes(hours)) {
            hour = `${parseFloat(hours) + 12}`;
        } else {
            hour = hours;
        }
        axios.get(`/search?d=${startDate?.toISOString().split("T")[0]}&t=${hour}:${minutes}`)
            .then(function (response) {
                console.log(response);
                if (response.data.detail) {
                    setCheckOut(true);
                } else {
                    setErr("Slot not available, please select another slot.")
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    };
    //
    const onChangeDate = (dates: [Date | null, Date | null]) => {
        setErr("");
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(start);
        // request back-end API to fetch all available slots on the selected start date.
        let fixedDate = convertformat(start);
        axios.get(`slots?current_date=${fixedDate}`)
            .then(function (response) {
                setTimeSlots(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
        // the count of returned list setTimeSlots(returned_list.length)
        console.log(end);
    };
    //
    return (
        <div
            className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
            data-nc-id="ListingFlightsPage"
        >
            <Helmet>
                <title>
                    {
                        lang
                            ?
                            "ALEX CURE"
                            :
                            "أليكس كيور"
                    }
                </title>
            </Helmet>
            {
                checkOut
                    ?
                    <CheckOutPagePageMain rDate={startDate?.toISOString().split("T")[0]}
                                          rTime={hours + ":" + minutes} lang={lang}/>
                    :
                    <div className="container relative">
                        {/* SECTION HERO */}
                        <SectionHeroArchivePage
                            currentPage="Flights"
                            currentTab="Flights"
                            className="pt-10 pb-24 lg:pb-28 lg:pt-16 "
                            startDate={startDate}
                            endDate={endDate}
                            onChangeDate={onChangeDate}
                            lang={lang}
                        />

                        {/* SECTION */}
                        {
                            err
                                ?
                                lang
                                    ?
                                    <span
                                        className="block text-neutral-500 dark:text-neutral-400 mt-3 text-center my-12">
                                        Sorry the picked slot on {startDate?.toDateString()}, {hours}:{minutes}.{["12", "01", "02", "03"].includes(hours) ? "PM" : "AM"} is busy.
                                        <br/>
                                        Please pick another slot.
                                        <span className="mx-2">·</span>
                                    </span>
                                    :
                                    <span
                                        className="block text-neutral-500 dark:text-neutral-400 mt-3 text-center my-12">
                                        عذرا, جميع الاماكن شاغرة في الفترة المختارة, من فضلك اختار فترة اخري.
                                    </span>
                                :
                                <SectionGridFilterCard className="pb-24 lg:pb-28" dateSlot={startDate}
                                                       timeSlots={timeSlots}
                                                       setTimeSlots={setTimeSlots} hours={hours} setHours={setHours}
                                                       minutes={minutes}
                                                       setMinutes={setMinutes} onSearch={onSearch} lang={lang}/>
                        }

                        {/* Contact Us */}
                        <div dir={lang ? "ltr" : "rtl"} className="mb-24 lg:mb-32">
                            <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                                {
                                    lang
                                        ?
                                        "Contact Us"
                                        :
                                        "تواصل معنا"
                                }
                            </h2>
                            <div className="container max-w-7xl mx-auto">
                                <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
                                    <div className="max-w-sm space-y-8">
                                        {info.map((item, index) => (
                                            <div key={index}>
                                                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                                                    {
                                                        lang
                                                            ?
                                                            item.title
                                                            :
                                                            item.arTitle
                                                    }
                                                </h3>
                                                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                                                    {
                                                        lang
                                                            ?
                                                            item.desc
                                                            :
                                                            item.arDesc
                                                    }
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                    <div>
                                        <form className="grid grid-cols-1 gap-6" action="#" method="post">
                                            <label className="block">
                                                <Label>
                                                    {
                                                        lang
                                                            ?
                                                            "Full name"
                                                            :
                                                            "الاسم"
                                                    }
                                                </Label>

                                                <Input
                                                    placeholder={
                                                        lang
                                                            ?
                                                            "Example Doe"
                                                            :
                                                            "الاسم"
                                                    }
                                                    type="text"
                                                    className="mt-1"
                                                />
                                            </label>
                                            <label className="block">
                                                <Label>
                                                    {
                                                        lang
                                                            ?
                                                            "Email address"
                                                            :
                                                            "البريد الالكتروني"
                                                    }
                                                </Label>

                                                <Input
                                                    type="email"
                                                    placeholder={
                                                        lang
                                                            ?
                                                            "example@example.com"
                                                            :
                                                            "البريد الالكتروني"
                                                    }
                                                    className="mt-1"
                                                />
                                            </label>
                                            <label className="block">
                                                <Label>
                                                    {
                                                        lang
                                                            ?
                                                            "Message"
                                                            :
                                                            "الرسالة"
                                                    }
                                                </Label>

                                                <Textarea className="mt-1" rows={6}/>
                                            </label>
                                            <div>
                                                <ButtonPrimary type="submit">{
                                                    lang
                                                        ?
                                                        "Send Message"
                                                        :
                                                        "ارسال"
                                                }</ButtonPrimary>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
};

export default ListingFlightsPage;
