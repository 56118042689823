import React, {FC, SetStateAction, useState} from "react";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import Select from "../../shared/Select/Select";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from "axios";
import PayPage from "../PayPage/PayPage";
import "styles/preloader.scss";

export interface CheckOutPagePageMainProps {
    className?: string;
    rDate: string | undefined;
    rTime: string | undefined;
    lang: boolean | true
}

export interface ReservationData {
    reservation_id: string;
    country_code: string;
    phone_number: string;
    gcc: string;
    name: string;
    email: string;
    reservation_time: string;
    reservation_date: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
                                                                 className = "",
                                                                 rDate,
                                                                 rTime,
                                                                 lang
                                                             }) => {

    const [name, setName] = useState("");
    const [nameERR, setNameERR] = useState("");
    const [email, setEmail] = useState("");
    const [emailErr, setEmailERR] = useState("");

    const [countryCode, setCountryCode] = useState("+20");
    const [countryCodeERR, setCountryCodeERR] = useState("");

    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberERR, setPhoneNumberERR] = useState("");

    const [GccSlip, setGccSlip] = useState("");
    const [GccSlipERR, setGccSlipERR] = useState("");

    const [Destination, setDestination] = useState("");
    const [DestinationERR, setDestinationERR] = useState("");

    const [Passport, setPassport] = useState("");
    const [PassportERR, setPassportERR] = useState("");

    const [notes, setNotes] = useState("");

    const [reservation, setReservation] = useState<ReservationData>();
    const [isLoad, setIsLoad] = useState(false);


    //
    const selectOptions = () => {
        const dists: string[] = ["Pleas Choos Your Destination", "KSA", "Oman", "UAE", "Qatar", "Kuwait", "Bahrain"];
        return (
            <>
                {
                    // eslint-disable-next-line array-callback-return
                    dists.map((value, index) =>
                        <option key={index} value={value}>
                            {value}
                        </option>
                    )
                }
            </>
        );
    }
    const selectOptionsAr = () => {
        interface countries {
            name: string;
            value: string
        }

        const dists: countries[] = [
            {
                name: "اختر الدولة",
                value: ""
            },
            {
                name: "السعودية",
                value: "KSA"
            },
            {
                name: "عمان",
                value: "Oman"
            },
            {
                name: "الامارات",
                value: "UAE"
            },
            {
                name: "قطر",
                value: "Qatar"
            },
            {
                name: "الكويت",
                value: "Kuwait"
            },
            {
                name: "البحرين",
                value: "Bahrain"
            }
        ];
        return (
            <>
                {
                    // eslint-disable-next-line array-callback-return
                    dists.map((value, index) =>
                        <option key={index} value={value.value}>
                            {value.name}
                        </option>
                    )
                }
            </>
        );
    }
    //
    const handleTime = (hm: string | undefined) => {
        if (hm) {
            let hour: string;
            let h: string = hm.split(":")[0];
            if (["01", "02", "03"].includes(h)) {
                hour = `${parseFloat(h) + 12}`;
            } else {
                hour = h;
            }
            return hour + ":" + hm.split(":").at(-1)
        } else {
            return null
        }
    }
    //
    const onSubmit = () => {
        setIsLoad(true);
        axios.post("create/", {
            reservation_date: rDate,
            reservation_time: handleTime(rTime),
            email: email,
            name: name,
            country_code: countryCode,
            phone_number: phoneNumber,
            gcc: GccSlip,
            passport: Passport,
            destination: Destination,
            notes: notes
        })
            .then(function (response) {
                setReservation(response.data);
                setIsLoad(false);
            })
            .catch(function (error) {
                // handle error
                setIsLoad(false);
                setEmail("");
                if (error.response.data.email !== undefined) {
                    lang ? setEmailERR("Please Enter a Valid Email") : setEmailERR("برجاء ادخال ايميل صحيح")
                }
                setName("");
                if (error.response.data.name !== undefined) {
                    lang ? setNameERR("Please Enter Your Name") : setNameERR("برجاء ادخال اسم صحيح")

                }
                setPhoneNumber("");
                if (error.response.data.phone_number !== undefined) {
                    lang ? setPhoneNumberERR("Please Enter Your Phone Number Maximum 10 numbers") : setPhoneNumberERR("برجاء ادخال رقم التلفون بحد اقصي 10 ارقام")
                }
                setPassport("");
                if (error.response.data.passport !== undefined) {
                    lang ? setPassportERR("Ensure your Password has 8 character") : setPassportERR("تاكد من ان رقم الباسبورت مكون من 8 حروف")

                }
                setGccSlip("");
                if (error.response.data.gcc !== undefined) {
                    lang ? setGccSlipERR("Ensure Your GCC has 17 character") : setGccSlipERR("تاكد من ان رقم ج س س مكون من 17 حرف")
                }
                setDestination("");
                if (error.response.data.destination !== undefined) {
                    lang ? setDestinationERR("Please select Destination") : setDestinationERR("برجاء اختيار الدولة")
                }

                setCountryCode("+20");
                if (error.response.data.country_code !== undefined) {
                    lang ? setCountryCodeERR("Ensure Country Code has no more than 3 characters.") : setCountryCodeERR("تاكد من كود المحافظه لا يتعدي 3 حروف")
                }
                console.log(error);
            });
    }

    const onChange = (value: string, setAtrr: React.Dispatch<SetStateAction<any>>) => {
        setAtrr(value);
    }
    const placeHolder = (inputName: string, lang: boolean) => {
            switch (inputName) {
                case "email":
                    if (lang) {
                        return "Email"
                    }
                    return "البريد الالكتروني"
                case "name":
                    if (lang) {
                        return "Full name"
                    }
                    return "الاسم بالكامل"
                case "number":
                    if (lang) {
                        return "Mobile Number"
                    }
                    return "رقم الهاتف"
                case "passport":
                    if (lang) {
                        return "Passport Number"
                    }
                    return "رقم جواز السفر"
                case "GCC":
                    if (lang) {
                        return "GCC slip number"
                    }
                    return "رقم GCC"
                case "distinction":
                    if (lang) {
                        return "Select distinction"
                    }
                    return "اختر الدولة"
                default:
                    return ""
            }
        }

    const renderMain = () => {
        return (
            <div dir={lang ? "ltr" : "rtl"}
                 className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
                <h2 className="text-3xl lg:text-4xl font-semibold">
                    {
                        lang
                            ?
                            "Confirm Reservation"
                            :
                            "تأكيد الحجز"
                    }
                </h2>
                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                <div>
                    <div>
                        <h3 className="text-2xl font-semibold">
                            {
                                lang
                                    ?
                                    "Your data"
                                    :
                                    "بيانات الحجز"
                            }
                        </h3>
                    </div>
                    <div
                        className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
                        <h3 className="p-4">{
                            lang
                                ?
                                "Picked date: "
                                :
                                "التاريخ "
                        }<span>{rDate}</span></h3>
                        <h3 className="p-4">{
                            lang
                                ?
                                "Picked time: "
                                :
                                "الوقت "
                        }<span>
                            {
                                rTime
                                    ?
                                    ["12", "01", "02", "03"].includes(rTime.split(":")[0]) ? rTime + ".PM" : rTime + ".AM"
                                    :
                                    null
                            }
                        </span></h3>
                    </div>
                </div>

                <div>
                    <h3 className="text-2xl font-semibold">{
                        lang
                            ?
                            "Complete your data"
                            :
                            "استكمال بينات الحجز"
                    }</h3>
                    <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

                    <div className="mt-6">
                        <div className="space-y-1">
                            <Label><span className="text-red-700">*</span>{
                                lang
                                    ?
                                    "Email"
                                    :
                                    "البريد الالكتروني"
                            }</Label>
                            <Input required={true} placeholder={placeHolder("email", lang)} onChange={(e) => {
                                onChange(e.target.value, setEmail)
                            }}/>
                            <p style={{fontSize: "small", marginLeft: "2%", color: "red"}}>{emailErr}</p>
                        </div>
                        <div className="space-y-1">
                            <Label><span className="text-red-700">*</span>{
                                lang
                                    ?
                                    "Name"
                                    :
                                    "الاسم"
                            }</Label>
                            <Input required={true} placeholder={placeHolder("name", lang)} onChange={(e) => {
                                onChange(e.target.value, setName)
                            }}/>
                            <p style={{fontSize: "small", marginLeft: "2%", color: "red"}}>{nameERR}</p>
                        </div>
                        <div className="flex space-x-5  ">
                            <div className="flex-1 space-y-1">
                                <Label><span className="text-red-700">*</span>{
                                    lang
                                        ?
                                        "Country code"
                                        :
                                        "مفتاح الدولة"
                                }</Label>
                                <Input defaultValue="+20" style={{direction: "ltr"}} disabled={true} required={true}
                                       onChange={(e) => {
                                           onChange(e.target.value, setCountryCode)
                                       }}/>
                                <p style={{fontSize: "small", marginLeft: "2%", color: "red"}}>{countryCodeERR}</p>
                            </div>
                            <div className="flex-1 space-y-1">
                                <Label><span className="text-red-700">*</span>{
                                    lang
                                        ?
                                        "Mobile number"
                                        :
                                        "رقم الهاتف"
                                }</Label>
                                <Input required={true} placeholder={placeHolder("number", lang)} onChange={(e) => {
                                    onChange(e.target.value, setPhoneNumber)
                                }}/>
                                <p style={{fontSize: "small", marginLeft: "2%", color: "red"}}>{phoneNumberERR}</p>
                            </div>
                        </div>
                        <div className="flex space-x-5  ">
                            <div className="flex-1 space-y-1">
                                <Label><span className="text-red-700">*</span>{
                                    lang
                                        ?
                                        "GCC Slip"
                                        :
                                        "GCC رقم"
                                }</Label>
                                <Input defaultValue="" placeholder={placeHolder("GCC", lang)} required={true} onChange={(e) => {
                                    onChange(e.target.value, setGccSlip)
                                }}/>
                                <p style={{fontSize: "small", marginLeft: "2%", color: "red"}}>{GccSlipERR}</p>
                            </div>
                            <div className="flex-1 space-y-1">
                                <Label><span className="text-red-700">*</span>{
                                    lang
                                        ?
                                        "Passport"
                                        :
                                        "رقم جواز السفر"
                                }</Label>
                                <Input required={true} placeholder={placeHolder("passport", lang)} onChange={(e) => {
                                    onChange(e.target.value, setPassport)
                                }}/>
                                <p style={{fontSize: "small", marginLeft: "2%", color: "red"}}>{PassportERR}</p>
                            </div>
                        </div>
                        <div className="space-y-1">
                            <Label><span className="text-red-700">*</span>{
                                lang
                                    ?
                                    "Destination"
                                    :
                                    "الدولة"
                            }</Label>
                            <Select required={true} placeholder={placeHolder("distinction", lang)} onChange={(e) => {
                                onChange(e.target.value, setDestination)
                            }}>
                                {
                                    lang
                                        ?
                                        selectOptions()
                                        :
                                        selectOptionsAr()
                                }
                            </Select>
                            <p style={{fontSize: "small", marginLeft: "2%", color: "red"}}>{DestinationERR}</p>
                        </div>
                        <div className="space-y-1">
                            <Label>{
                                lang
                                    ?
                                    "Notes"
                                    :
                                    "ملاحظات"
                            }</Label>
                            <Textarea placeholder="..." onChange={(e) => {
                                onChange(e.target.value, setNotes)
                            }}/>
                            <span className="text-sm text-neutral-500 block">
                                {
                                    lang
                                        ?
                                        "Write a few sentences about what you want."
                                        :
                                        "بعض الملاحظات ان وجدت."
                                }
                            </span>
                        </div>
                        <div className="pt-8">
                            <ButtonPrimary onClick={onSubmit}>{
                                lang
                                    ?
                                    "Submit"
                                    :
                                    "تقدم"
                            }</ButtonPrimary>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const preLoader = () => {
        return (
            <div style={{
                minHeight: "70vh"
            }}>
                <svg className="spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                </svg>
            </div>
        );
    }

    return (
        <div className={`nc-CheckOutPagePageMain ${className}`}>
            {
                isLoad
                    ?
                    preLoader()
                    :
                    reservation
                        ?
                        <PayPage data={reservation} lang={lang}/>
                        :
                        <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
                            {renderMain()}
                        </main>
            }
        </div>
    );
};

export default CheckOutPagePageMain;
